import React, { useEffect, useState } from 'react'; // useEffect
import Banners from 'src/components/desktop/explore/banners';
// import Creations from 'src/components/desktop/explore/creations';
import IntroComponent from 'src/components/explore/introComponent';
import MainRepplComponent from 'src/components/explore/mainRepplComponent';
import RepplComponent from 'src/components/explore/repplComponent';
import MultiRepplComponent from 'src/components/explore/multiRepplComponent';
import MiniRepplComponent from 'src/components/explore/miniRepplComponent';
import StudioComponent from 'src/components/explore/studioComponent';
import TribeComponent from 'src/components/explore/tribeComponent';
import CollectionsComponent from 'src/components/explore/collectionsComponent';
// import { UserContext, UserContextType } from 'src/contexts/UserContext';
// import loginAuth from 'src/heplers/auth-page';
// import Loading from 'src/components/common/loading/loading';
import { useIsTablet } from 'src/hooks/use-is-mobile';

// import Router from 'next/router';

// import { UserContext, UserContextType } from 'src/contexts/UserContext';

// import { logoutAuth } from 'src/heplers/auth-page';
// import Loading from 'src/components/common/loading/loading';

function IndexPage() {
  const isTablet = useIsTablet();
  // const { isLogin } = useContext(UserContext) as UserContextType;

  // const isAuth = logoutAuth(isLogin, '/_internal');

  // // Server-render loading state
  // if (!isAuth || isLogin) {
  //   return <Loading />;
  // }
  // const handleScroll = () => {
  //   const triangle = document.getElementById(`triangle-wrap`);
  //   const element = document.getElementById(`explore-collections`);
  //   const reactElement = element.getBoundingClientRect();
  //   if (reactElement.y < 60) {
  //     triangle.classList.add('fixed');
  //     triangle.classList.remove('absolute');
  //   } else {
  //     triangle.classList.remove('fixed');
  //     triangle.classList.add('absolute');
  //   }
  // };

  // useEffect(() => {
  //   Router.push('/agora');
  //   window.addEventListener('scroll', handleScroll, { passive: true });
  //   return () => window.removeEventListener('scroll', handleScroll);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const [exploreContent, setExploreContent] = useState<any>();
  const [bannerContent, setBannerContent] = useState<any>();

  useEffect(() => {
    const exploreArray = [];
    fetch(
      `https://career.1tm.io/api-json/wp/v2/pages/73349?timestamp=${Date.now()}`
    )
      .then((res) => res.json())
      .then((result) => {
        Object.entries(result.acf).forEach((e: any[]) => {
          exploreArray.push(...e[1]);
        });
        exploreArray.sort(sortCompare);
        setBannerContent(result.acf.group1[0]);
        setExploreContent(exploreArray);
      });
  }, []);

  useEffect(() => {});

  // const { isLogin } = useContext(UserContext) as UserContextType;
  // const isAuth = loginAuth(isLogin, '/signin');

  /* eslint-disable */
  const sortCompare = (a: any, b: any) => {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    var aA = a.order.replace(reA, "");
    var bA = b.order.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a.order.replace(reN, ""), 10);
      var bN = parseInt(b.order.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  };
  /* eslint-enable */

  // if (!isAuth || !isLogin) {
  //   return <Loading />;
  // }
  // return <></>;

  // return (
  //   <div>
  //     <Banners />
  //     <Creations />
  //     <Creators />
  //     <RepplComponent repplData={exploreData.repplComponent} />
  //     <div className="relative bg-white dark:bg-gray-800 pt-20">
  //       <div id="triangle-wrap" className="absolute left-0 explore-triangle" />
  //       <Collections />
  //       <Tribes />
  //     </div>
  //   </div>
  // );

  return (
    <div
      style={{ padding: `${isTablet ? '0 0 100px 0' : '0 24px 150px'}` }}
      className="header-initial div-offest"
    >
      {bannerContent?.display && (
        <div className="bg-gradient">
          <div
            style={{ padding: `${isTablet ? '89px 0 0 0' : '126px 0 0 0'}` }}
          >
            <div
              style={{ width: `${isTablet ? '327px' : '579px'}` }}
              className="text-gray-900 font-bold m-auto text-center md:pb-1 tracking-tight"
            >
              <div style={{ fontSize: `${isTablet ? '32px' : '48px'}` }}>
                Explore
              </div>
            </div>
          </div>
          <div className="block">
            <div className="py-6">
              <Banners data={bannerContent.banner} />
            </div>
          </div>
          <style jsx global>{`
            .bg-gradient {
              background: linear-gradient(
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 1),
                rgba(230, 232, 233, 1)
              );
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          `}</style>
        </div>
      )}
      {exploreContent?.map((c) => {
        if (c.section_name === 'intro' && c.display) {
          return <IntroComponent key={c.sequence} data={c} />;
        }
        if (c.section_name === 'mainRepplComponent' && c.display) {
          return <MainRepplComponent key={c.sequence} data={c} />;
        }
        if (c.section_name === 'multiRepplComponent' && c.display) {
          return <MultiRepplComponent key={c.sequence} data={c} />;
        }
        if (c.section_name === 'repplComponent' && c.display) {
          return <RepplComponent key={c.sequence} data={c} />;
        }
        if (c.section_name === 'miniRepplComponent' && c.display) {
          return <MiniRepplComponent key={c.sequence} data={c} />;
        }
        if (c.section_name === 'tribeComponent' && c.display) {
          return <TribeComponent key={c.sequence} data={c} />;
        }
        if (c.section_name === 'studioComponent' && c.display) {
          return <StudioComponent key={c.sequence} data={c} />;
        }
        if (c.section_name === 'collectionComponent' && c.display) {
          return <CollectionsComponent key={c.sequence} data={c} />;
        }
        return <></>;
      })}
    </div>
  );
}

export default IndexPage;
